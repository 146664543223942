import React, { useEffect, useState } from 'react';
import { Download as DownloadIcon } from '@mui/icons-material';

interface ImageContentProps {
  isGeneratingImage: boolean;
  generatedImageUrl: string | null;
  imageLoaded: boolean;
  showDownloadButton: boolean;
  handleDownload: () => void;
  setImageLoaded: (loaded: boolean) => void;
}

const ImageContent: React.FC<ImageContentProps> = ({
  isGeneratingImage,
  generatedImageUrl,
  imageLoaded,
  showDownloadButton,
  handleDownload,
  setImageLoaded
}) => {
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey(prevKey => prevKey + 1);
    setImageLoaded(false);
  }, [generatedImageUrl, setImageLoaded]);

  if (!generatedImageUrl) {
    return null;
  }

  return (
    <div className="image-frame" key={key}>
      <img
        src={`${generatedImageUrl}?${new Date().getTime()}`}
        alt="Generated content"
        onLoad={() => setImageLoaded(true)}
        style={{ 
          display: imageLoaded ? 'block' : 'none',
          maxWidth: '100%',
          height: 'auto',
        }}
      />
      {showDownloadButton && imageLoaded && (
        <button className="download-button" onClick={handleDownload}>
          <span>Download</span>
          <DownloadIcon />
        </button>
      )}
    </div>
  );
};

export default ImageContent;