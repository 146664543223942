import React, { useState, useEffect, useRef, useCallback } from 'react';
import './App.css';
import HomePage from './components/HomePage';
import './index.css';
import ChatPage from './components/ChatPage';
import SettingsPage from './components/SettingsPage';
import { useAuth0 } from '@auth0/auth0-react';
import PricingPage from './components/PricingPage';
import { TooltipProvider } from './components/ui/tooltip';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate, Navigate } from 'react-router-dom';
import LoadingSpinner from './components/LoadingSpinner';
import { Tool, FollowUpPrompt, ChatResponse } from './types';
import ErrorBoundary from './components/ErrorBoundary';
import { useWebSocketConnection } from './hooks/useWebSocketConnection';

type Page = 'home' | 'chat' | 'settings' | 'pricing';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';
const WS_URL = process.env.REACT_APP_WS_URL || 'ws://localhost:3001/ws';

if (!API_URL || !WS_URL) {
  console.error('API_URL or WS_URL is not defined in the environment variables');
}

function AppContent() {
  const { isAuthenticated, isLoading: authLoading, user, loginWithRedirect, logout, getAccessTokenSilently } = useAuth0();
  const [description, setDescription] = useState('');
  const [chatMessages, setChatMessages] = useState<string[]>([]);
  const [localUser, setLocalUser] = useState(user);
  const [imageKey, setImageKey] = useState<string | null>(null);
  const [imageBase64, setImageBase64] = useState<string | null>(null);
  const [generatedImageUrl, setGeneratedImageUrl] = useState<string | null>(null);
  const [allowUnauthenticatedChat, setAllowUnauthenticatedChat] = useState(false);
  const [isAppLoading, setIsAppLoading] = useState(true);
  const [userDataKey, setUserDataKey] = useState<string | null>(null);
  const [followUpPrompts, setFollowUpPrompts] = useState<FollowUpPrompt[]>([]);
  const [authChecked, setAuthChecked] = useState(false);
  const [latestCampaign, setLatestCampaign] = useState<any>(null);
  const [currentBrand, setCurrentBrand] = useState<any>(null);
  const [forceUpdate, setForceUpdate] = useState(0);
  const [isUsingTool, setIsUsingTool] = useState(false);
  const [currentTool, setCurrentTool] = useState<string | null>(null);

  const location = useLocation();
  const navigate = useNavigate();

  const { ws, connect, disconnect } = useWebSocketConnection(WS_URL);

  const [isGeneratingImage, setIsGeneratingImage] = useState(false);

  const setIsGeneratingImageWithLog: React.Dispatch<React.SetStateAction<boolean>> = useCallback((value) => {
    console.log('setIsGeneratingImage called with value:', value);
    console.trace('setIsGeneratingImage call stack');
    setIsGeneratingImage(value);
  }, []);

  const handleWebSocketMessage = useCallback((event: MessageEvent) => {
    const data = JSON.parse(event.data);
    console.log('WebSocket message received:', data);
    switch (data.type) {
      case 'toolUseUpdate':
        console.log('Received toolUseUpdate message');
        setIsUsingTool(true);
        setCurrentTool(data.tool);
        if (data.tool === 'adjust_image' || data.tool === 'generate_new_image') {
          setIsGeneratingImageWithLog(true);
        }
        break;
      case 'imageGenerationStarted':
        console.log('Received imageGenerationStarted message');
        setIsGeneratingImageWithLog(true);
        break;
      case 'newImageGenerated':
        console.log('Received newImageGenerated message');
        setGeneratedImageUrl(data.imageUrl);
        setImageKey(data.imageKey);
        setIsGeneratingImageWithLog(false);
        setIsUsingTool(false);
        setCurrentTool(null);
        break;
      default:
        console.log('Unknown WebSocket message type:', data.type);
    }
  }, [setGeneratedImageUrl, setImageKey, setIsGeneratingImageWithLog, setIsUsingTool, setCurrentTool]);

  useEffect(() => {
    if (ws) {
      ws.onmessage = handleWebSocketMessage;
    }
  }, [ws, handleWebSocketMessage]);

  useEffect(() => {
    if (!authLoading) {
      setIsAppLoading(false);
    }
  }, [authLoading]);

  useEffect(() => {
    if (!authLoading) {
      if (isAuthenticated && user) {
        createOrUpdateUser(user);
        const savedMessages = localStorage.getItem('unauthenticatedChatMessages');
        const savedImageUrl = localStorage.getItem('unauthenticatedGeneratedImageUrl');
        if (savedMessages) {
          setChatMessages(JSON.parse(savedMessages));
          localStorage.removeItem('unauthenticatedChatMessages');
        }
        if (savedImageUrl) {
          setGeneratedImageUrl(savedImageUrl);
          localStorage.removeItem('unauthenticatedGeneratedImageUrl');
        }
        if (location.pathname === '/') {
          navigate('/chat', { replace: true });
        }
      } else {
        if (location.pathname === '/chat' && !allowUnauthenticatedChat) {
          navigate('/', { replace: true });
        }
      }
    }
  }, [authLoading, isAuthenticated, user, navigate, location.pathname, allowUnauthenticatedChat]);

  const createOrUpdateUser = async (user: any) => {
    try {
      const storedUserDataKey = localStorage.getItem('userDataKey');
      const response = await axios.post(`${API_URL}/api/users`, {
        sub: user.sub,
        name: user.name,
        email: user.email,
        picture: user.picture,
        userDataKey: storedUserDataKey
      }, {
        withCredentials: true
      });
      setLocalUser({...response.data, auth0Id: user.sub});
      localStorage.removeItem('userDataKey');
    } catch (error) {
      console.error('Error creating/updating user:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response data:', error.response?.data);
        console.error('Response status:', error.response?.status);
        console.error('Response headers:', error.response?.headers);
      }
    }
  };

  const updateUserNick = async (nick: string) => {
    if (!localUser) return;
    try {
      const response = await axios.put(`${API_URL}/api/users/${localUser.id}`, { nick });
      setLocalUser(prevUser => ({ ...prevUser, nick }));
    } catch (error) {
      console.error('Error updating user nick:', error);
      throw error;
    }
  };

  const updateUserName = async (name: string) => {
    if (!user) return;
    try {
      const response = await axios.put(`${API_URL}/api/users/${user.sub}`, { name });
      setLocalUser(prevUser => ({ ...prevUser, name }));
    } catch (error) {
      console.error('Error updating user name:', error);
      throw error;
    }
  };

  const handleSubmit = async () => {
    if (description.trim()) {
      setAllowUnauthenticatedChat(true);
      
      navigate('/chat');

      try {
        const response = await fetch(`${API_URL}/api/chat`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 
            message: description, 
            aspectRatio: '1:1'
          })
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        
        setChatMessages([`User: ${description}`, `AI: ${data.content}`]);
        if (data.imageUrl) {
          setGeneratedImageUrl(data.imageUrl);
        }
        if (data.imageKey) {
          setImageKey(data.imageKey);
          localStorage.setItem('imageKey', data.imageKey);
        }
        if (data.userDataKey) {
          setUserDataKey(data.userDataKey);
          localStorage.setItem('userDataKey', data.userDataKey);
        }
      } catch (error) {
        console.error('Error submitting chat:', error);
        setChatMessages([`Error: An error occurred while processing your request.`]);
      } finally {
        setIsGeneratingImageWithLog(false);
      }
    }
  };

  const handleLogin = () => {
    if (!isAuthenticated && chatMessages.length > 0) {
      localStorage.setItem('unauthenticatedChatMessages', JSON.stringify(chatMessages));
      if (generatedImageUrl) {
        localStorage.setItem('unauthenticatedGeneratedImageUrl', generatedImageUrl);
      }
    }
    localStorage.setItem('loginRedirectPath', location.pathname);
    loginWithRedirect();
  };

  const sendMessage = async (message: string, aspectRatio: string): Promise<ChatResponse> => {
    console.log('sendMessage called');
    
    // Ensure WebSocket connection is established
    const socket = ws || connect();

    try {
      // Add user message immediately
      setChatMessages(prevMessages => [...prevMessages, `User: ${message}`]);

      const response = await axios.post(`${API_URL}/api/tool-chat`, {
        messages: [{ role: "user", content: [{ type: "text", text: message }] }],
        authToken: user?.sub,
        imageKey,
        aspectRatio
      });

      console.log('Received response from server:', response.data);

      // Add AI response and tool summary
      setChatMessages(prevMessages => [
        ...prevMessages,
        `AI: ${response.data.aiResponse}`,
        ...(response.data.toolUseSummary ? [`Tool Summary: ${response.data.toolUseSummary}`] : [])
      ]);

      // Delay adding campaign info
      if (response.data.campaignInfo) {
        setTimeout(() => {
          setChatMessages(prevMessages => [
            ...prevMessages,
            `CAMPAIGN_INFO:${response.data.campaignInfo}`
          ]);
        }, 100); // 100ms delay, adjust as needed
      }

      // The image updates will now be handled by the WebSocket connection

      // Disconnect WebSocket after 1 minute of inactivity
      setTimeout(() => {
        disconnect();
      }, 60000); // 60 seconds = 1 minute

      return response.data;
    } catch (error) {
      console.error('Error sending message:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response data:', error.response?.data);
        console.error('Response status:', error.response?.status);
      }
      setChatMessages(prevMessages => [...prevMessages, `Error: ${error instanceof Error ? error.message : 'An unknown error occurred'}`]);
      throw error;
    }
  };

  useEffect(() => {
    if (!authLoading) {
      setAuthChecked(true);
    }
  }, [authLoading]);

  const renderPage = () => {
    if (!authChecked) {
      return <LoadingSpinner />;
    }

    switch (location.pathname) {
      case '/':
        return isAuthenticated ? (
          <Navigate to="/chat" replace />
        ) : (
          <HomePage
            description={description}
            setDescription={setDescription}
            onSubmit={handleSubmit}
            isAuthenticated={isAuthenticated}
            user={localUser}
            loginWithRedirect={loginWithRedirect}
            logout={logout}
            onSettingsClick={() => navigate('/settings')}
            onPricingClick={() => navigate('/pricing')}
          />
        );
      case '/chat':
        return (
          <ChatPage
            initialDescription={description}
            chatMessages={chatMessages}
            setChatMessages={setChatMessages}
            generatedImageUrl={generatedImageUrl}
            setGeneratedImageUrl={setGeneratedImageUrl}
            isGeneratingImage={isGeneratingImage}
            setIsGeneratingImage={setIsGeneratingImageWithLog}
            sendMessage={sendMessage}
            isAuthenticated={isAuthenticated}
            user={user}
            loginWithRedirect={handleLogin}
            logout={logout}
            onSettingsClick={() => navigate('/settings')}
            onPricingClick={() => navigate('/pricing')}
            latestCampaign={latestCampaign}
            currentBrand={currentBrand}
            forceUpdate={forceUpdate}
            imageKey={imageKey}
            setImageKey={setImageKey}
            aspectRatio={'1:1'}
            followUpPrompts={followUpPrompts}
            setFollowUpPrompts={setFollowUpPrompts}
            isUsingTool={isUsingTool}
            currentTool={currentTool}
          />
        );
      case '/settings':
        return isAuthenticated ? (
          <SettingsPage
            onClose={() => navigate(-1)}
            onSettingsClick={() => navigate('/settings')}
            isAuthenticated={isAuthenticated}
            localUser={localUser}
            loginWithRedirect={loginWithRedirect}
            logout={logout}
            onPricingClick={() => navigate('/pricing')}
            updateUserName={updateUserName}
            updateUserNick={updateUserNick}
          />
        ) : (
          <Navigate to="/" replace />
        );
      case '/pricing':
        return <PricingPage onClose={() => navigate(-1)} />;
      default:
        return <Navigate to="/" />;
    }
  };

  useEffect(() => {
    const setAuthToken = async () => {
      if (isAuthenticated) {
        try {
          const token = await getAccessTokenSilently();
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        } catch (error) {
        }
      }
    };

    setAuthToken();
  }, [isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    return () => {
      disconnect();
    };
  }, [disconnect]);

  return (
    <TooltipProvider delayDuration={100}>
      <div className="App">
        {renderPage()}
      </div>
    </TooltipProvider>
  );
}

function App() {
  return (
    <ErrorBoundary>
      <Router>
        <AppContent />
      </Router>
    </ErrorBoundary>
  );
}

export default App;