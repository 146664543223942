import React, { forwardRef } from 'react';
import Header from './Header';
import { Send as SendIcon, DiamondOutlined } from '@mui/icons-material';
import '../styles/HomePage.css';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip"

interface HomePageProps {
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  onSubmit: () => void;
  isAuthenticated: boolean;
  user: any; // Replace 'any' with a more specific type if available
  loginWithRedirect: () => void;
  logout: () => void;
  onSettingsClick: () => void;
  onPricingClick: () => void;
}

const HomePage = forwardRef<HTMLDivElement, HomePageProps>(({
  description,
  setDescription,
  onSubmit,
  isAuthenticated,
  user,
  loginWithRedirect,
  logout,
  onSettingsClick,
  onPricingClick,
}, ref) => {
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      onSubmit();
    }
  };

  return (
    <div className="home-page" ref={ref}>
      <Header 
        page="home" 
        onBackClick={() => {}} 
        isAuthenticated={isAuthenticated}
        user={user}
        loginWithRedirect={loginWithRedirect}
        logout={logout}
        onSettingsClick={onSettingsClick}
        onPricingClick={onPricingClick}
      />
      <div className="content">
        <header className="App-header">
          <h1><span className="focus-text">Marketing made easy.</span></h1>
          <p className="App-description">So you can focus on what you do best.</p>
        </header>
        <div className="container">
          <div className="input-container">
            <input
              type="text"
              placeholder="Describe your service or product"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <button 
              className={`forward-button ${description ? 'active' : ''}`}
              onClick={onSubmit}
              disabled={!description.trim()}
            >
              <SendIcon />
            </button>
          </div>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <button className="train-model-button">
                  <DiamondOutlined className="diamond" />
                  <span>Train model with your product image</span>
                </button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Upload your product images to improve AI-generated content</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
    </div>
  );
});

export default HomePage;