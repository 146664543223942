import React from 'react';
import { ChatInputProps } from '../types';
import { Send as SendIcon } from '@mui/icons-material';
import { Button } from "./ui/button";
import { FollowUpPrompt } from '../types';

const ChatInput: React.FC<ChatInputProps> = ({
  chatInput,
  setChatInput,
  handleChatSubmit,
  isLoading,
  followUpPrompts,
  onPromptClick,
  handleKeyPress
}) => {
  return (
    <div className="chat-input-container bg-black bg-opacity-70 p-4 w-full">
      <div className="relative w-full flex items-center">
        <div className="flex-grow relative mr-2">
          <textarea
            placeholder="Type your message..."
            value={chatInput}
            onChange={(e) => setChatInput(e.target.value)}
            onKeyPress={handleKeyPress}
            disabled={isLoading}
            className="bg-gray-800 bg-opacity-70 text-white w-full p-3 rounded-lg resize-none"
            style={{
              height: '90px',
              overflow: 'auto',
            }}
          />
          {chatInput === '' && followUpPrompts.length > 0 && (
            <div className="absolute bottom-3 left-3 right-3 flex flex-wrap gap-1 z-10">
              {followUpPrompts.map((prompt, index) => (
                <Button
                  key={index}
                  variant="outline"
                  size="sm"
                  onClick={() => onPromptClick(prompt.text)}
                  className="text-xs bg-transparent bg-opacity-50 text-muted-foreground border-gray-700 rounded-full py-0.5 px-2 text-[10px] h-6 mb-1"
                >
                  {prompt.label}
                </Button>
              ))}
            </div>
          )}
        </div>
        <button 
          className={`send-button ${chatInput && !isLoading ? 'active' : ''} bg-gray-800 bg-opacity-70 rounded-lg w-[60px] h-[60px] flex items-center justify-center`}
          onClick={handleChatSubmit}
          disabled={!chatInput.trim() || isLoading}
        >
          <SendIcon style={{ fontSize: '24px' }} />
        </button>
      </div>
    </div>
  );
};

export default ChatInput;