import React, { useState, useEffect, useRef, forwardRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { Send as SendIcon, Download as DownloadIcon } from '@mui/icons-material';
import PulsatingCircles from './PulsatingCircles';
import CreativeLoadingAnimation from './CreativeLoadingAnimation';
import Header from './Header';
import '../styles/ChatPage.css';
import CustomAlertDialog from './ui/CustomAlertDialog';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ExamplePromptsCard from './ExamplePromptsCard';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "./ui/sheet"
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "./ui/hover-card"
import { Input } from "../components/ui/input"
import { Button } from "../components/ui/button"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select"
import OnboardingModal from './OnboardingModal';
import { Card, CardHeader, CardTitle, CardContent } from "./ui/card";
import { Collapsible, CollapsibleTrigger, CollapsibleContent } from "./ui/collapsible";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./ui/tooltip";
import { ChevronDown, ChevronUp, Copy } from 'lucide-react';
import CampaignInfo from './CampaignInfo';
import ImageContent from './ImageContent';
import ChatInput from './ChatInput';
import AIResponse from './AIResponse';
import { FollowUpPrompt, ChatResponse } from '../types';
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { Image as ImageIcon } from '@mui/icons-material';

interface ChatPageProps {
  initialDescription: string;
  chatMessages: string[];
  setChatMessages: React.Dispatch<React.SetStateAction<string[]>>;
  generatedImageUrl: string | null;
  setGeneratedImageUrl: React.Dispatch<React.SetStateAction<string | null>>;
  isGeneratingImage: boolean;
  setIsGeneratingImage: React.Dispatch<React.SetStateAction<boolean>>;
  sendMessage: (message: string, aspectRatio: string) => Promise<ChatResponse>;
  isAuthenticated: boolean;
  user: any;
  loginWithRedirect: () => void;
  logout: () => void;
  onSettingsClick: () => void;
  onPricingClick: () => void;
  latestCampaign: any;
  currentBrand: any;
  forceUpdate: number;
  imageKey: string | null;
  setImageKey: React.Dispatch<React.SetStateAction<string | null>>;
  aspectRatio: string;
  followUpPrompts: FollowUpPrompt[];
  setFollowUpPrompts: React.Dispatch<React.SetStateAction<FollowUpPrompt[]>>;
  isUsingTool: boolean;
  currentTool: string | null;
}

const ChatPage = forwardRef<HTMLDivElement, ChatPageProps>((props, ref) => {
  const {
    initialDescription,
    chatMessages,
    setChatMessages,
    generatedImageUrl,
    setGeneratedImageUrl,
    isGeneratingImage,
    setIsGeneratingImage,
    sendMessage,
    isAuthenticated,
    user,
    loginWithRedirect,
    logout,
    onSettingsClick,
    onPricingClick,
    latestCampaign,
    currentBrand,
    forceUpdate,
    imageKey,
    setImageKey,
    aspectRatio,
    followUpPrompts,
    setFollowUpPrompts,
    isUsingTool,
    currentTool,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [chatInput, setChatInput] = useState('');
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const chatMessagesRef = useRef<HTMLDivElement>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [forceUpdateState, setForceUpdateState] = useState(0);
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isOnboardingOpen, setIsOnboardingOpen] = useState(!isAuthenticated);

  const scrollToBottom = () => {
    setTimeout(() => {
      if (chatMessagesRef.current) {
        chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
      }
    }, 100);
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages, generatedImageUrl]);

  useEffect(() => {
    if (generatedImageUrl) {
      setImageLoaded(false);
      setShowDownloadButton(false);
      setForceUpdateState(prev => prev + 1);
    }
  }, [generatedImageUrl]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (imageLoaded) {
      const timer = setTimeout(() => {
        setShowDownloadButton(true);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [imageLoaded]);

  useEffect(() => {
    const storedImageKey = localStorage.getItem('imageKey');
    if (storedImageKey) {
      setImageKey(storedImageKey);
    }
  }, [setImageKey]);

  const handleChatSubmit = async () => {
    if (!isAuthenticated) {
      setShowLoginDialog(true);
    } else if (chatInput.trim()) {
      const messageToSend = chatInput.trim();
      setChatInput('');
      setIsLoading(true);
      setError(null);
      
      // Remove the immediate addition of user message here
      // We'll let App.tsx handle this to avoid duplication
      
      try {
        const response = await sendMessage(messageToSend, aspectRatio);
        console.log('Response from sendMessage:', response);
        setFollowUpPrompts(response.followUpPrompts || []);
        if (response.imageKey) {
          setImageKey(response.imageKey);
          localStorage.setItem('imageKey', response.imageKey);
        }
      } catch (err) {
        console.error('Error sending message:', err);
        setError('An error occurred while processing your message. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleDownload = () => {
    if (generatedImageUrl) {
      window.open(generatedImageUrl, '_blank');
    }
  };

  const handleLogin = () => {
    localStorage.setItem('loginRedirectPath', '/chat');
    if (chatMessages.length > 0) {
      localStorage.setItem('unauthenticatedChatMessages', JSON.stringify(chatMessages));
      if (generatedImageUrl) {
        localStorage.setItem('unauthenticatedGeneratedImageUrl', generatedImageUrl);
      }
    }
    loginWithRedirect();
  };

  useEffect(() => {
    if (isAuthenticated) {
      const savedMessages = localStorage.getItem('unauthenticatedChatMessages');
      const savedImageUrl = localStorage.getItem('unauthenticatedGeneratedImageUrl');
      if (savedMessages) {
        setChatMessages(JSON.parse(savedMessages));
        localStorage.removeItem('unauthenticatedChatMessages');
      }
      if (savedImageUrl) {
        setGeneratedImageUrl(savedImageUrl);
        localStorage.removeItem('unauthenticatedGeneratedImageUrl');
      }
      setIsOnboardingOpen(false);
    }
  }, [isAuthenticated, setChatMessages, setGeneratedImageUrl]);

  const handleOnboardingComplete = (sessionData: any) => {
    setIsOnboardingOpen(false);
    loginWithRedirect();
  };

  const handleSelectPrompt = (text: string) => {
    setChatInput(text);
  };

  const handlePromptClick = (text: string) => {
    setChatInput(text);
    setFollowUpPrompts([]);
  };

  const renderCampaignInfo = (campaignInfo: string) => {
    return (
      <CampaignInfo
        campaignInfo={campaignInfo}
        latestCampaign={latestCampaign}
        currentBrand={currentBrand}
        setChatMessages={setChatMessages}
        onSettingsClick={onSettingsClick}
      />
    );
  };

  const renderUserMessage = (message: string, index: number) => {
    const content = message.replace(/^User: /, '').trim();
    return (
      <div key={index} className="chat-message user-message bg-gray-800 bg-opacity-70 p-3 rounded-lg mb-2 flex items-center">
        <Avatar className="w-6 h-6 mr-2 flex-shrink-0">
          <AvatarImage src={user?.picture} alt={user?.name} />
          <AvatarFallback>{user?.name?.charAt(0) || 'U'}</AvatarFallback>
        </Avatar>
        <div className="flex-grow">{content}</div>
      </div>
    );
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleChatSubmit();
    }
  };

  const renderImageContent = () => {
    if (isGeneratingImage) {
      return <CreativeLoadingAnimation />;
    } else if (!generatedImageUrl) {
      return (
        <div className="image-placeholder flex flex-col items-center justify-center bg-transparent">
          <ImageIcon style={{ fontSize: 48, color: '#6b7280' }} />
          <p className="mt-4 text-gray-500 text-center">Your generated image will appear here.</p>
        </div>
      );
    } else {
      return (
        <ImageContent
          isGeneratingImage={isGeneratingImage}
          generatedImageUrl={generatedImageUrl}
          imageLoaded={imageLoaded}
          showDownloadButton={showDownloadButton}
          handleDownload={handleDownload}
          setImageLoaded={setImageLoaded}
        />
      );
    }
  };

  return (
    <ErrorBoundary>
      <div key={forceUpdateState} className="chat-page bg-black text-white" ref={ref}>
        <Header 
          page="chat" 
          onBackClick={() => {}}
          isAuthenticated={isAuthenticated}
          user={user}
          loginWithRedirect={loginWithRedirect}
          logout={logout}
          onSettingsClick={onSettingsClick}
          onPricingClick={onPricingClick}
        />
        <div className={`split-screen ${isMobile ? 'mobile' : ''}`}>
          {isMobile && (
            <div className="image-container">
              {renderImageContent()}
            </div>
          )}
          <div className="chat-container bg-black bg-opacity-70">
            <div className="chat-messages bg-black bg-opacity-70" ref={chatMessagesRef}>
              <ExamplePromptsCard onSelectPrompt={handleSelectPrompt} />
              {chatMessages.map((message, index) => {
                if (message.startsWith('User:')) {
                  return renderUserMessage(message, index);
                } else if (message.startsWith('AI:')) {
                  const content = message.replace(/^AI: /, '').trim();
                  return (
                    <React.Fragment key={`ai-message-${index}`}>
                      {content && (
                        <div className="chat-message ai-message bg-gray-900 bg-opacity-70 p-3 rounded-lg mb-1 mt-1 relative">
                          <AIResponse content={content} isToolSummary={false} />
                        </div>
                      )}
                      {chatMessages[index + 1]?.startsWith('CAMPAIGN_INFO:') && (
                        renderCampaignInfo(chatMessages[index + 1].replace('CAMPAIGN_INFO:', '').trim())
                      )}
                    </React.Fragment>
                  );
                } else if (message.startsWith('CAMPAIGN_INFO:')) {
                  return renderCampaignInfo(message.replace('CAMPAIGN_INFO:', '').trim());
                } else if (message.startsWith('Tool Summary:')) {
                  const content = message.replace(/^Tool Summary: /, '').trim();
                  if (content && content !== "No tools were used in generating this response.") {
                    return (
                      <div key={`tool-summary-${index}`} className="chat-message tool-summary bg-gray-900 bg-opacity-70 p-3 rounded-lg mb-1 mt-1 relative">
                        <AIResponse content={content} isToolSummary={true} />
                      </div>
                    );
                  }
                }
                return null;
              })}
              {isLoading && <PulsatingCircles />}
              {error && <div className="error-message">{error}</div>}
            </div>
            <ChatInput
              chatInput={chatInput}
              setChatInput={setChatInput}
              handleChatSubmit={handleChatSubmit}
              isLoading={isLoading}
              followUpPrompts={followUpPrompts}
              onPromptClick={handlePromptClick}
              handleKeyPress={handleKeyPress}
            />
          </div>
          {!isMobile && (
            <div className="image-container">
              {renderImageContent()}
            </div>
          )}
        </div>
        <CustomAlertDialog
          isOpen={showLoginDialog}
          onOpenChange={setShowLoginDialog}
          title="Login Required"
          description="You need to be logged in to continue chatting. Would you like to log in now?"
          confirmText="Log In"
          onConfirm={() => {
            setShowLoginDialog(false);
            handleLogin();
          }}
        />
        <OnboardingModal
          isOpen={isOnboardingOpen}
          onClose={() => setIsOnboardingOpen(false)}
          productDescription={initialDescription}
          onComplete={handleOnboardingComplete}
        />
        {isUsingTool && (
          <div className="tool-use-indicator">
            Using tool: {currentTool}
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
});

export default ChatPage;